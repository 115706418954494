import { RESET_CONTACT_EMAIL } from './GlobalConstants';

export const GENERIC_ROLE_ERROR = 'You don\'t have authorization to visit the requested page';
export const GENERIC_API_ERROR = `Something went wrong. Contact ${RESET_CONTACT_EMAIL} if the problem persist.`;

export const COMPANY_ADD_SUCCESS = 'Company added successfully';
export const COMPANY_EDIT_SUCCESS = 'Company updated successfully';
export const COMPANY_DELETE_SUCCESS = 'Company deleted successfully';

export const DEPARTMENT_ADD_SUCCESS = 'Department added successfully';
export const DEPARTMENT_EDIT_SUCCESS = 'Department updated successfully';
export const DEPARTMENT_DELETE_SUCCESS = 'Department deleted successfully';

export const EMPLOYEE_ADD_SUCCESS = 'Employee added successfully';
export const EMPLOYEE_EDIT_SUCCESS = 'Employee updated successfully';
export const EMPLOYEE_DELETE_SUCCESS = 'Employee deleted successfully';

export const JOB_ADD_SUCCESS = 'Job added successfully';
export const JOB_EDIT_SUCCESS = 'Job updated successfully';
export const JOB_DELETE_SUCCESS = 'Job deleted successfully';
export const JOB_APPROVER_CHANGE_SUCCESS = 'Job approver changed successfully';
export const JOB_APPROVER_CHANGE_MINIMUM = 'Please select the job items';

export const COST_CODE_ADD_SUCCESS = 'Cost code added successfully';
export const COST_CODE_EDIT_SUCCESS = 'Cost code updated successfully';

export const REQUEST_ADD_SUCCESS = 'Request created successfully';
export const REQUEST_FILE_UPLOAD_SUCCESS = 'File uploaded in temporary location';
export const REQUEST_FILE_SAVE_SUCCESS = 'File saved successfully';
export const REQUEST_FILE_REMOVE_SUCCESS_TEMP = 'File got removed from temporary location';
export const REQUEST_FILE_REMOVE_SUCCESS = 'File removed successfully';

// export const REQUEST_STATUS_UPDATE = 'Request status updated successfully';
export const REQUEST_STATUS_SPECIFIC_UPDATE = {
  REJECT: '%s request rejected successfully',
  APPROVE: '%s request approved successfully',
  REJECT_ADMIN: '%s request rejected by admin successfully',
  REVISE: '%s request is successfully sent for revision',
  COST_UPDATE: '%s request costcode updated successfully',
}
export const ASSET_STATUS_UPDATE = 'Asset status updated successfully';

// export const EDIT_PERMISSION_ERROR = `You don't have permission to edit this request. Contact ${RESET_CONTACT_EMAIL} if you're the owner of the request`;

export const EDIT_PASSWORD_SUCCESS = 'Password updated successfully';
export const INVALID_PASSWORD_ENTERED = 'Invalid current password. Please try again!';
export const INVALID_LOGIN_ENTERED = 'Your username or password is invalid. Please try again!';
export const INACTIVE_ACCOUNT = `Your account is inactive. Please contact support at ${RESET_CONTACT_EMAIL}`;
export const SESSION_ACCOUNT_EXPIRED = 'Your session expired, please login again';
export const SERVER_DOWN = 'API server is down. Please try again later';
